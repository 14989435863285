<template>
	<v-layout column justify-start pa-1 fill-height>
		<v-flex shrink align-right justify-start>
			<v-list-tile v-show="!loading" @click="addFlag">
				<v-list-tile-avatar>
					<v-icon color="primary">add</v-icon>
				</v-list-tile-avatar>
				<v-list-tile-content>
					<v-list-tile-title v-t="'sa.flags.add_flag'" />
				</v-list-tile-content>
			</v-list-tile>
		</v-flex>
		<v-flex fill-height scroll-y>
				<v-list>
					<v-list-tile v-if="loading">
						<v-list-tile-action>
							<v-progress-circular color="primary" indeterminate />
						</v-list-tile-action>
						<v-list-tile-title v-t="'loading_progress'" />
					</v-list-tile>
					<v-list v-for="flag in flags" v-else :key="flag.id">
						<FlagsManagerLine :value="flag" @save="saveFlag" @delete="deleteFlag" />
					</v-list>
				</v-list>
		</v-flex>
	</v-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'

export default {
	name: 'FlagsManager',
	components: {
		FlagsManagerLine: () => ({
			component: import('@/components/SuperAdmin/Flags/FlagsManagerLine.vue')
		})
	},
	mixins: [SuperAdminModuleGuard],
	data: function () {
		return {
			emptyFlag: { name: '' },
			flags: [],
			loading: false
		}
	},
	created: function () {
		this.getFlags()
	},
	methods: {
		addFlag: function () {
			this.flags.unshift(Object.assign({}, this.emptyFlag))

		},
		getFlags: function () {
			this.loading = true
			this.service
				.getFlags()
				.then(res => {
					res.map(item => {
						this.flags.push(Object.assign({}, item))
					})
				})
				.finally(() => {
					this.loading = false
				})
		},
		saveFlag: function (flag) {
			if (flag.id) {
				this.service.updateFlag(flag.id, flag).then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('flag.messages.flag_updated'))
				})
			} else {
				this.service
					.createFlag(flag)
					.then(res => {
						this.flags = this.flags.map(item => {
							return item.name === res.name ? res : item
						})
					})
					.then(() => {
						this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('flag.messages.flag_created'))
					})
			}
		},
		deleteFlag: function (flag) {
			if (flag.id) {
				this.$dialog
					.warning({
						text: this.$t('sa.flags.actions.are_you_sure', { name: flag.name }),
						title: this.$t('sa.flags.actions.delete'),
						actions: {
							false: this.$t('actions.no'),
							true: this.$t('actions.yes')
						}
					})
					.then(res => {
						if (res) {
							this.service
								.deleteFlag(flag.id)
								.then(() => {
									this.flags = this.flags.filter(item => item !== flag)
								})
								.then(() => {
									this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('flag.messages.flag_deleted'))
								})
						}
					})
			} else {
				this.flags = this.flags.filter(item => item !== flag)
			}
		}
	}
}
</script>
